<template>
  <div
    ref="layerscontainer"
    class="timeline-grid-content-layers flex flex-col"
  >
    <div class="timeline-content-area bg-gray-800 border-gray-700 h-full relative">
      <template v-if="loaded">
        <div
          class="timeline-content-area bg-gray-800 border-gray-700 relative"
          @click.prevent.stop="onClick"
        >
          <TimelineScrubberLine
            :scale="scale"
            :current-progresspc="currentProgresspc"
          />

          <transition-group
            type="transition"
            :name="!drag ? 'flip-list' : null"
          >
            <TimelineLayerRow
              v-for="layer in layers"
              :key="layer.id"
              :layer.sync="layer"
              :modules="modules"
              :scale="adjustedScale"
              :overallduration="duration"
              :show-debug="showDebug"
              :active-module-id="activeModuleId"
              @view-module="$emit('view-module', $event)"
              @mouse-down="$emit('mouse-down', $event)"
              @drag-move-module="$emit('drag-move-module', $event)"
              @drag-move-module-end="$emit('drag-move-module-end', $event)"
              @drag-move-module-start="$emit('drag-move-module-start', $event)"
              @new-module="$emit('new-module', $event)"
            />
          </transition-group>
          <TimelineLayerEmpty
            :scale="adjustedScale"
            :overallduration="duration"
            @drag-move-module="$emit('drag-move-module', $event)"
            @new-module="$emit('new-module', $event)"
          />
        </div>
      </template>
      <template v-else>
        <div
          v-if="loading"
          class="text-gray-500 flex h-full w-full items-center justify-center select-none"
        >
          <div class="">
            <fa-icon
              icon="spinner"
              spin
              class="fa-fw"
            />
            Initializing Timeline
          </div>
        </div>
        <div
          v-else
          class="text-gray-500 flex h-full w-full items-center justify-center select-none"
        >
          <div class="">
            <fa-icon
              icon="xmark"
              class="text-red-500 fa-fw"
            />
            Failed to load timeline
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
const TimelineLayerEmpty = () => import('./layers/TimelineLayerEmpty')
const TimelineLayerRow = () => import('./layers/TimelineLayerRow')
const TimelineScrubberLine = () => import('./layers/TimelineScrubberLine')

export default {

  components: {
    TimelineLayerRow,
    TimelineLayerEmpty,
    TimelineScrubberLine
  },
  props: ['layers', 'modules', 'duration', 'scale', 'scroll', 'currentProgresspc', 'showDebug', 'activeModuleId'],

  data () {
    return {
      clientScale: null,
      clientWidth: null,
      scrollWidth: null,
      drag: false
    }
  },

  computed: {
    adjustedScale () {
      return this.scale * this.clientScale
    },

    loaded () {
      return true
    },

    loading () {
      return false
    }
  },

  watch: {
    scroll (value) {
      this.lockScroll()
    },
    duration: {
      handler: function () {
        this.calcContainerScale()
      },
      immediate: true
    },

    clientScale: {
      handler: function (value) {
        this.$emit('updated-client-scale', value)
      }
    }
  },

  mounted () {
    this.initWidths()
    this.lockScroll()
    this.$refs.layerscontainer.addEventListener('scroll', this.onScroll)
  },

  beforeDestroy () {
    this.$refs.layerscontainer.removeEventListener('scroll', this.onScroll)
    this.$refs.layerscontainer.removeEventListener('resize', this.onResize)
  },

  methods: {

    onResize () {
      this.initWidths()
    },

    onClick () {
      this.$emit('view-module', null)
      // this.$bus.$emit('editor:sidebar:clear')
    },

    lockScroll () {
      let scrollPx = this.scrollWidth * this.scroll

      this.$nextTick(() => {
        if (this.$refs.layerscontainer !== undefined) {
          this.$refs.layerscontainer.scrollLeft = scrollPx
        }
      })
    },

    onScroll (event) {
      if (this.$refs.layerscontainer !== undefined) {
        let offset = this.$refs.layerscontainer.scrollLeft
        let scrollPc = offset / this.scrollWidth
        this.$emit('update-scroll', scrollPc)
      }
    },

    initWidths () {
      const ro = new ResizeObserver((entries, observer) => {
        entries.forEach(() => {
          this.updateWidths()
        })
      })

      ro.observe(this.$refs.layerscontainer)
    },

    updateWidths () {
      if (this.$refs.layerscontainer !== undefined) {
        this.clientWidth = this.$refs.layerscontainer.clientWidth
        this.scrollWidth = this.$refs.layerscontainer.scrollWidth
        this.calcContainerScale()
      }
    },

    calcContainerScale () {
      if (this.duration > 0) this.clientScale = this.clientWidth / this.duration
    }

  }
}
</script>
